import {init, reactRouterV5Instrumentation} from "@sentry/react";
import {BrowserTracing} from "@sentry/browser";
import {HttpClient as HttpClientIntegration} from "@sentry/integrations";
import {createBrowserHistory} from "history";

const history = createBrowserHistory();

init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    new BrowserTracing({
      tracePropagationTargets: ["localhost", "fsacademy.bg", /^\//],
      routingInstrumentation: reactRouterV5Instrumentation(history)
    }),
    new HttpClientIntegration()
  ],
  release: import.meta.env.VITE_BUILD_VERSION,
  environment: import.meta.env.VITE_SENTRY_ENV,
  enabled: import.meta.env.VITE_SENTRY_ENV === "production",
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: import.meta.env.VITE_SENTRY_ENV === "production" ? 0.1 : 0
});
